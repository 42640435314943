<template>
  <div>
    <v-card flat class="px-1 mt-1">
      <v-card-text class="pa-0">
        <!-- <h3 v-if="projectEquipmentObj.isProjectEquipment" class="pa-1 pl-2">{{$t('message.projects.project')}}: {{printProjectName}} -->
          <!-- <v-divider></v-divider> -->
        <h3 v-if="projectFullName && projectEquipmentObj.projectId" class="pa-1 pl-2">{{$t('message.projects.project')}}: <router-link :to="`/projects/${projectEquipmentObj.projectId}`">{{ projectFullName }}</router-link>
        </h3>
        <compact-list :payload="payload" :key="reInit"></compact-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { hostAppApi } from '../../plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
// import projectMixin from '@/mixins/projectMixin'
export default {
  // mixins: [PermissionsMixin, projectMixin],
  mixins: [PermissionsMixin],
  data () {
    return {
      search: '',
      loading: false,
      payload: {
        module: '',
        moduleTitle: 'message.layout.equipments',
        pagination: {},
        totalCount: 0,
        loading: false,
        editHandler: null,
        actionSize: '0', // for delete action
        list: [],
        completeList: [],
        items: [{
          value: 'equipment_number',
          class: 'mt-4 pa-0 pa-1',
          cols: '4',
          spanClass: 'body pl-2'
        }, {
          value: '',
          cols: '7',
          class: 'pa-0 pl-5',
          rowClass: 'mt-1',
          subItems: [{
            value: 'name',
            class: 'pa-0',
            cols: '12',
            spanClass: 'body'
          }, {
            value: 'equipment_status',
            class: 'pa-0',
            cols: '12',
            spanClass: 'subtitle'
          }]
        }],
        addBtnTo: '',
        hasDelete: false,
        isLivePagination: true,
        showScanCode: true,
        hasNoNext: true
      },
      reInit: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.EQUIPMENT_INDEX) ? this.$store.state.common.EQUIPMENT_INDEX : { itemsPerPage: 6, page: 1 },
      equipmentsCloneList: [],
      awaitingSearch: false,
      hostRef: this.$cookie.get('AxentivPwa_host-ref'),
      MODULE: '',
      projectEquipmentObj: {
        isProjectEquipment: false,
        projectId: 0
      },
      projectFullName: ''
    }
  },
  created () {
    this.MODULE = 'equipment'
    if (this.$route.name === 'project_equipment') {
      this.MODULE = 'projectequipment'
      this.projectEquipmentObj.isProjectEquipment = true
      this.projectEquipmentObj.projectId = parseInt(this.$route.params.project_id)
      // this.getProject(parseInt(this.$route.params.project_id))
    }
    this.payload.editHandler = (item) => this.$router.push(this.projectEquipmentObj.isProjectEquipment ? `/equipments/${item.id}?project_id=${this.projectEquipmentObj.projectId}` : `/equipments/${item.id}`)
    this.payload.addBtnTo = this.projectEquipmentObj.isProjectEquipment ? `/equipments/add?project_id=${this.projectEquipmentObj.projectId}` : '/equipments/add'
    this.$store.dispatch('getGeneralSettings')
    if (this.$store.state.common.isEquipmentExcluded) this.$router.push('/')
  },
  components: {
    'compact-list': () => import('@/components/CompactList.vue')
  },
  mounted () {
    this.getListHandler()
    // const text = this.$t('message.common.deleteSuccess')
    const userid = this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        const payload = { url: `equipment/${data.id}?user_id=${parseInt(userid)}`, isSingle: true, module: this.MODULE, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.deleteSuccess' })
        this.$store.commit('hideDialog')
        this.getListHandler()
      }
    })
    // Load list on page
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
  },
  methods: {
    getListHandler (paginationObj) {
      const pagination = paginationObj || this.pagination
      this.payload.pagination.page = pagination.page
      this.payload.loading = true
      this.payload.module = this.MODULE
      const search = pagination.search ? pagination.search : ''
      const pageVal = pagination.page // search ? 1 : pagination.page
      const model = {
        start: ((pageVal - 1) * this.pagination.itemsPerPage),
        length: this.pagination.itemsPerPage,
        search: search
      }
      let url = `https://${this.hostRef}/api/equipment?only_items=1&start=${model.start}&length=${model.length}`
      if (!this.payload.showScanCode && search) url += `&search=${search}`
      if (this.projectEquipmentObj.isProjectEquipment) url += `&project_id=${this.projectEquipmentObj.projectId}`
      hostAppApi.get(url, { headers: { language: this.$i18n.locale } })
        .then((response) => {
          if (response.data) {
            const { data, recordsFiltered, projectFullName } = response.data
            this.payload.total = recordsFiltered
            /* const transformData = {
              date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
            }
            data = this.$formatter.formatListModel(data, transformData) */
            const totalCount = Math.ceil(recordsFiltered / pagination.itemsPerPage)
            if (pagination.page === totalCount) this.payload.hasNoNext = true
            else this.payload.hasNoNext = false
            this.payload.list = data
            this.projectFullName = projectFullName
            if (this.reInit === 0) this.reInit++
            /* this.equipmentsCloneList = this.$formatter.cloneVariable(response.data.data)
            this.setVisibleEquipments() */
          }
        })
        .finally(() => { this.payload.loading = false })
    },
    setVisibleEquipments () {
      const visibleEquipments = this.equipmentsCloneList.filter(
        x =>
          x.equipment_number.toString().includes(this.search) ||
          (x.name ? x.name.toString().includes(this.search.toLowerCase()) : '')
      )
      this.payload.completeList = visibleEquipments
      this.$eventBus.$emit('changePage', 1)
    }
  },
  watch: {
    /* search (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.setVisibleEquipments()
          this.awaitingSearch = false
        }, 500) // .5 sec delay
      }
      this.awaitingSearch = true
    } */
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('loadList')
  }
}
</script>
<style lang="css" scoped>
.custom_model_close {
  position: absolute;
  top: 1px;
  right: 3px;
}
</style>
